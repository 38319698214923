import React from 'react';
import '../../App.css';
import Styles from './Unleashed.module.css'

const Unleashed = () => {
  return (
    <div className={`${Styles.Aboutbg}`}>
      <div className={Styles.AboutBody}>
        <div className={`${"width50"}`}>
          <div className={`${"width90"} ${Styles.leftContent}`}>
            <img src={require('../../assest/images/unleashed.png')} alt="Unleashed" />
            <div className={Styles.title}>Unleashed</div>
            <div className={Styles.tagLine}>Defining<span>the</span>abstract</div>
          </div>
        </div>
        <div className={`${"width50"} ${Styles.AboutContent}`}>
          <div className={`${"width90"}`}>
            <h1>Unleashed</h1>
            <p>
              Creativity when caged always find a way to break-free. That's the inspiration that coined "Unleashed-Defining the abstract". Pooja Bajaj's solo art show comes to life on the heels of the pandemic. When the din died down, her creativity surfaced to be found. Come, witness art characterized by a kaleidoscope of colour, inter twined in never-ending love with nature.
            </p>
            <div className={Styles.VenueContent}>
              <div className={Styles.Circle}>
                <span>Save The Date</span>
              </div>
              <div>
                <ul className={Styles.VenueContentUl}>
                  <li>
                    <div>Inaugration on Monday,</div>
                    <span>10 October 2022 | 4:30 p.m. to 7:00 p.m.</span>
                  </li>
                  <li>
                    <div>On view from,</div>
                    <span>11 October 2022 to 16 October 2022 </span>
                    <span>11:00 a.m. to 7:00 p.m.</span>
                  </li>
                  <li>
                    <div>At Jehangir Art Gallery No.4</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unleashed;