import React from 'react';
import '../../App.css';
import Styles from './Gallery.module.css';
import { Backdrop, CircularProgress, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import galleryJson from '../../data/gallery.json';

const Gallery = () => {

  let images = [];
  let items = [];
  const [open, setOpen] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(false);
  const [modalProps, setModalProps] = React.useState();

  function importAll(r) {
    return r.keys().map(r);
  }

  images = importAll(require.context('../../assest/images/gallery/', false, /\.(png|jpe?g|svg)$/));
  items = galleryJson;

  function removeExtension(filename) {
    let fn = '';
    fn = filename.substring(filename.lastIndexOf('/') + 1)
    fn = fn.replace(/\.[^\/.]+$/, '')
    return fn.replace(/\.[^\/.]+$/, '');
  }

  function setupgallery() {
    items.map(i => {
      images.findIndex(element => {
        if (i.title === removeExtension(element)) {
          i.image = element;
        }
        if ((i.title + '_tn') === removeExtension(element)) {
          i.thumbnail = element;
        }
      })
    })
    return items
  }
  const gallery = setupgallery()

  const handleOpen = (modalItem) => {
    setOpenLoader(true)
    setOpen(true)
    setModalProps(modalItem);
  };
  const handleClose = () => {
    setOpen(false)
  };
  const imageLoaded = () => {
    setOpenLoader(false)
  }

  return (
    <div className={Styles.GalleryView}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={Styles.PaintingModal}
      ><div className={Styles.ModalContent}>
          <CloseIcon className={Styles.ModalClose} onClick={() => handleClose()} />
          {
            modalProps && (<div>
              <Backdrop
                sx={{ color: '#fff', zIndex: 99 }}
                open={openLoader}
                style={{ backgroundColor: '#000000' }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <img src={modalProps.image} alt={modalProps.title} onLoad={imageLoaded} />
            </div>)
          }
        </div>
      </Modal>
      <div className={Styles.galleryItems}>
        <ul>
          {gallery.sort((first, second) => {
            return first.position > second.position ? 1 : -1;
          }).map(function (item, index) {
            return (<li key={index} onClick={() => handleOpen(item)}>
              <div className={Styles.galleryImgTile}><img src={item.thumbnail} alt={item.title} /></div>
            </li>);
          })}
        </ul>
      </div>
    </div>
  );
};

export default Gallery;