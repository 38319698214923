import React, { useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import '../../App.css';
import Styles from './ShowsPaintings.module.css'
const gallopseriesI = require('../../assest/images/gallop_series.png')
const bondofloverseriesI = require('../../assest/images/bondoflove_series.png')
const roarseriesI = require('../../assest/images/roar_series.png')

const ShowsPaintings = () => {
  const [showMenu, setShowMenu] = useState(true);
  const navigate = useNavigate();

  const setMenu = (toshow) => {
    console.log("setMenu", toshow)
    setShowMenu(toshow)
  }
  window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
  window.addEventListener('popstate', (event) => {
    if (event.state) {
      navigate('/shows/paintings');
      setShowMenu(true)
    }
  }, false);
  if (!setShowMenu) {
    setMenu(true)
  }

  return (
    <div className={Styles.PaintingsView}>
      {showMenu && <div className={Styles.PaintingsBlock} id={'paintingsBlock'}>
        <div className={Styles.item}>
          <img src={gallopseriesI} alt="Gallop Series" />

          <NavLink to='/shows/paintings/thegallopseries' onClick={() => setMenu(false)}>
            <div className={Styles.ItemMask}><h2>The Gallop Series</h2></div>
          </NavLink>

        </div>
        <div className={Styles.item}>
          <img src={bondofloverseriesI} alt="Bond Of Love Series" />
          <NavLink to='/shows/paintings/thebondofloveseries' onClick={() => setMenu(false)}>
            <div className={Styles.ItemMask}><h2>The Bond Of Love Series</h2></div>
          </NavLink>
        </div>
        <div className={Styles.item}>
          <img src={roarseriesI} alt="Roar Series" />
          <NavLink to='/shows/paintings/theroarseries' onClick={() => setMenu(false)}>
            <div className={Styles.ItemMask}><h2>The Roar Series</h2></div>
          </NavLink>
        </div>
      </div>
      }
      <Outlet />
    </div>
  );
};

export default ShowsPaintings;