import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../App.css';
import Styles from './Paintings.module.css';
import gallopseries from '../../data/gallopseries.json';
import bondofloveseries from '../../data/bondofloveseries.json';
import roarseries from '../../data/roarseries.json';
import myjourney from '../../data/myjourney.json';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Paintings = (params) => {
  const { showBackLink } = params;
  const { paintingId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(false);
  const [modalProps, setModalProps] = React.useState();
  const pricingRef = React.createRef();

  useEffect(() => {
    let nl = document.getElementById('NavLogo')
    let nlm = document.getElementById('mobNavLogo')
    if (nl && window.innerWidth > 768) nl.style.display = "block"
    if (nlm && window.innerWidth <= 768) nlm.style.display = "block"
    const myElement = document.getElementById("paintingsBlock");
    if (myElement) myElement.style.display = "none";
    if (showBackLink)
      pricingRef.current.style.top = '13vh';
    else
      pricingRef.current.style.top = '8vh';
  });


  let images = [];
  let items = [];
  let galleryTitle = '';
  let quote = '';
  function importAll(r) {
    return r.keys().map(r);
  }
  if (paintingId === 'thegallopseries') {
    images = importAll(require.context('../../assest/images/thegallopseries/', false, /\.(png|jpe?g|svg)$/));
    items = gallopseries;
    galleryTitle = 'The Gallop Series';
    quote = '"Some Horses will Test You, Some will Teach You and Some will bring out the Best in You"'
  } else if (paintingId === 'thebondofloveseries') {
    images = importAll(require.context('../../assest/images/thebondofloverseries/', false, /\.(png|jpe?g|svg)$/));
    items = bondofloveseries;
    galleryTitle = 'The Bond Of Love Series';
    quote = '"To the World you are the Mother But to your family YOU ARE THE WORLD"'
  } else if (paintingId === 'theroarseries') {
    images = importAll(require.context('../../assest/images/theroarseries/', false, /\.(png|jpe?g|svg)$/));
    items = roarseries;
    galleryTitle = 'The Roar Series';
    quote = '"The Tiger lies low Not from Fear but for Aim"'
  } else if ((paintingId === 'myjourney')) {
    images = importAll(require.context('../../assest/images/myjourney/', false, /\.(png|jpe?g|svg)$/));
    items = myjourney;
    galleryTitle = 'My Journey';
    quote = '"Not all Who Wander are Lost"'
  }

  function removeExtension(filename) {
    let fn = '';
    fn = filename.substring(filename.lastIndexOf('/') + 1)
    fn = fn.replace(/\.[^\/.]+$/, '')
    return fn.replace(/\.[^\/.]+$/, '');
  }

  function setupgallery() {
    items.map(i => {
      images.findIndex(element => {
        if (i.title === removeExtension(element)) {
          i.image = element;
        }
        if ((i.title + '_tn') === removeExtension(element)) {
          i.thumbnail = element;
        }
      })
    })
    return items
  }
  const gallery = setupgallery()

  const handleOpen = (modalItem) => {
    setOpenLoader(true)
    setOpen(true)
    setModalProps(modalItem);
  };
  const handleClose = () => {
    setOpen(false)
  };
  const imageLoaded = () => {
    setOpenLoader(false)
  }
  return (

    <div className={Styles.PaintingView}>
      {showBackLink && <NavLink to='/shows/paintings' reloadDocument={true} className={Styles.back}>
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" fill="black" />
        </svg>
        Back
      </NavLink>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={Styles.PaintingModal}
      ><div className={Styles.ModalContent}>
          <CloseIcon className={Styles.ModalClose} onClick={() => handleClose()} />
          {modalProps && (<div>
            <Backdrop
              sx={{ color: '#fff', zIndex: 99 }}
              open={openLoader}
              style={{ backgroundColor: '#000000' }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <img src={modalProps.image} alt={modalProps.title} onLoad={imageLoaded} />
            <div className={Styles.galleryTitle}>{modalProps.title}</div>
            <div className={Styles.galleryContents}>{modalProps.type}</div>
            <div className={Styles.galleryContents}>Size: {modalProps.size}</div>
            <div className={Styles.galleryContents}>Price: {modalProps.cost}</div>
          </div>)}
        </div>
      </Modal>
      <h1 className={Styles.title}>{galleryTitle}</h1>
      <h1 className={Styles.quote}>{quote}</h1>
      <a href="mailto:artbypoojabajaj@gmail.com" className={Styles.Pricing} ref={pricingRef}>
        <MailOutlineIcon /><span className={Styles.BtnText}>Price On Request</span>
      </a>
      <div className={Styles.galleryItems}>
        <ul>
          {gallery.sort((first, second) => {
            return first.position > second.position ? 1 : -1;
          }).map(function (item, index) {
            return (<li key={index} onClick={() => handleOpen(item)}>
              <div className={Styles.galleryImgTile}><img src={item.thumbnail} alt={item.title} /></div>
              <div className={Styles.PaintingSpecifications}>
                <div className={Styles.galleryTitle}>{item.title}</div>
                <div className={Styles.galleryContents}>{item.type}</div>
                <div className={Styles.galleryContents}>Size: {item.size}</div>
                {/* <div className={Styles.galleryContents}>Price: {item.cost}</div> */}
              </div>
            </li>);
          })}
        </ul>
      </div>
    </div>

  );
};

export default Paintings;