import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import Styles from './Navbar.module.css';
import '../../App.css';
import { IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

const Navbar = () => {
    const HamBtn = React.createRef();
    const closeMenu = () => {
        if (window.innerWidth <= 768 && HamBtn.current.checked) {
            HamBtn.current.checked = false
        }
    }
    return (
        <div className="App">
            <nav className={Styles.NavNavbar}>
                <input className={Styles.HamBtn} type="checkbox" id="HamBtn" ref={HamBtn} />
                <label className={Styles.HamIcon} htmlFor="HamBtn"><span className={Styles.NavIcon}></span></label>
                <img id={'mobNavLogo'} className={Styles.mobNavLogo} src={require('../../assest/images/Logo.png')} alt="App Logo" />
                <div className={Styles.NavItems}>
                    <img id={'NavLogo'} className={Styles.NavLogo} src={require('../../assest/images/Logo.png')} alt="App Logo" />
                    <ul className={Styles.NavUl}>
                        <li>
                            <NavLink to='/' onClick={() => closeMenu()}>
                                {({ isActive }) => (
                                    <svg width="20" height="17" viewBox="0 0 20 17" fill={isActive ? "#FE0000" : "#000000"} xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z" />
                                    </svg>
                                )}

                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/abouttheartist' onClick={() => closeMenu()} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                                About the Artist
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/shows' onClick={() => closeMenu()} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                                Shows
                            </NavLink>
                        </li>
                        <li>
                            <div className={Styles.submenuNav}>
                                <input type="checkbox" id="SubPaint" className={Styles.submenuCheck} />
                                <label className={Styles.NavItem} htmlFor="SubPaint"> Paintings</label>

                                <div className={Styles.submenuItems}>
                                    <NavLink to='/paintings/myjourney' onClick={() => closeMenu()} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                                        My Journey
                                    </NavLink>
                                    <NavLink to='/paintings/thegallopseries' onClick={() => closeMenu()} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                                        The Gallop Series
                                    </NavLink>
                                    <NavLink to='/paintings/thebondofloveseries' onClick={() => closeMenu()} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                                        The bond of love Series
                                    </NavLink>
                                    <NavLink to='/paintings/theroarseries' onClick={() => closeMenu()} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                                        The Roar Series
                                    </NavLink>
                                </div>
                            </div>
                        </li>
                        <li>
                            <NavLink to='/contactus' onClick={() => closeMenu()} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
            <main>
                <Outlet />
            </main>
            <footer>
                <div className={Styles.ConnectUs}><span>Connect With Us</span>
                    <a href="https://www.instagram.com/artbypoojabajaj/" target='_blank'>
                        <IconButton aria-label="InstagramIcon" className={Styles.IconBtn}>
                            <InstagramIcon />
                        </IconButton>
                    </a>
                </div>
                <p className={Styles.Copyright}>Copyright © {(new Date().getFullYear())} Art By Pooja Bajaj. All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default Navbar;