import React, { useEffect } from 'react';
import '../../App.css';
import Background from '../../assest/images/Homebg.png'
import Styles from './Home.module.css';
import { Backdrop, CircularProgress } from '@mui/material';

const Home = () => {
  const [openLoader, setOpenLoader] = React.useState(true);
  const imageLoader = new Image();
  imageLoader.src = Background;

  useEffect(() => {
    if (window.location.pathname === '/') {
      let nl = document.getElementById('NavLogo')
      let nlm = document.getElementById('mobNavLogo')
      if (nl && window.innerWidth > 768) nl.style.display = "none"
      if (nlm && window.innerWidth <= 768) nlm.style.display = "none"
    }
  });
  imageLoader.onload = () => {
    setOpenLoader(false)
  };
  return (
    <div className="AppViews" style={{ backgroundImage: `url(${Background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 99 }}
        open={openLoader}
        style={{ backgroundColor: '#000000b3' }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={Styles.LogoMask}>
        <img src={require('../../assest/images/Logolarge.png')} alt="App Logo Large" className={Styles.ImgCenter} />
      </div>
    </div >

  );
};

export default Home;