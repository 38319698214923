import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import '../../App.css';
import ShowsNavbar from './ShowsNavbar';

const Shows = () => {
    const navigate = useNavigate();
    useEffect(() => {
        let nl = document.getElementById('NavLogo')
        let nlm = document.getElementById('mobNavLogo')
        if (nl && window.innerWidth > 768) nl.style.display = "block"
        if (nlm && window.innerWidth <= 768) nlm.style.display = "block"
        if (window.location.pathname === '/shows')
            navigate('/shows/unleashed')
    });
    return (
        <div className="AppViews">
            <ShowsNavbar />

            <Outlet />
        </div>
    );
};

export default Shows;