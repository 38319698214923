import React from 'react';
import { NavLink } from 'react-router-dom';
import Styles from './Shows.module.css';

const ShowsNavbar = () => {
    return (
        <nav className={Styles.ShowsNavbar}>
            <ul className={Styles.NavUl}>
                <li>
                    <NavLink to='/shows/unleashed' className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                        About the Show
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/shows/paintings' reloadDocument={true} className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                        Paintings
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/shows/photos' className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                        Photos
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/shows/media' className={({ isActive }) => isActive ? Styles.NavActive : Styles.NavInactive}>
                        Media
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default ShowsNavbar;