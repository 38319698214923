import React from 'react';
import '../../App.css';
import Styles from './Media.module.css'
import malwarPdf from '../../assest/pdfs/InTheNewsMarwar.pdf'
import sancturyPdf from '../../assest/pdfs/Sanctury.pdf'
import DownloadIcon from '@mui/icons-material/Download';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const Media = () => {
  return (
    <div className={Styles.MediaView}>
      <div className={Styles.MediaContent}>
        <div className={Styles.width50}>
          <div>
            <img src={require('../../assest/images/media/InTheNewsMarwar.jpeg')} alt="In the news Marwar" />
            <a href={malwarPdf} download={malwarPdf}><DownloadIcon fontSize={'small'} style={{ marginRight: '4px' }} rel="noreferrer" /> Download </a>
          </div>
          <div>
            <img src={require('../../assest/images/media/Marwar_India.jpeg')} alt="Marwar_India instagram post" />
            <a href={'https://www.instagram.com/p/ClF1-_OK-j8/'} target='_blank' rel="noreferrer"><ReadMoreIcon fontSize={'small'} style={{ marginRight: '4px' }} /> Read More </a>
          </div>

        </div>
        <div className={Styles.width50}>
          <div>
            <img src={require('../../assest/images/media/Sanctury.jpg')} alt="sanctury" />
            <a href={sancturyPdf} download={sancturyPdf}><DownloadIcon fontSize={'small'} style={{ marginRight: '4px' }} rel="noreferrer" /> Download </a>
          </div>
          <div>
            <img src={require('../../assest/images/media/LokmatArticle.jpg')} alt="LokmatArticle" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;