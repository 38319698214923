
import './App.css';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import Home from './views/home/Home';
import About from './views/about/About';
import Navbar from './components/navbar/Navbar';
import Shows from './views/shows/Shows';
import ContactUs from './views/contactus/ContactUs';
import Paintings from './views/paintings/Paintings';
import Unleashed from './views/shows/Unleashed';
import ShowsPaintings from './views/shows/ShowsPaintings';
import Gallery from './views/shows/Gallery';
import Media from './views/shows/Media';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Navbar />}>
      <Route index element={<Home />} />
      <Route path='abouttheartist' element={<About />} />
      <Route path='shows' element={<Shows />}>
        <Route path='unleashed' index element={<Unleashed />} />
        <Route path='paintings' element={<ShowsPaintings />} >
          <Route path=':paintingId'
            element={<Paintings showBackLink={true} />}
          />
        </Route>
        <Route path='photos' element={<Gallery />} />
        <Route path='media' element={<Media />} />
      </Route>
      <Route path='paintings/:paintingId'
        element={<Paintings />}
      />
      <Route path='contactus' element={<ContactUs />} />
    </Route>
  )
)
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
