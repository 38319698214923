import React from 'react';
import '../../App.css';
import Styles from './About.module.css'

const About = () => {
  let nl = document.getElementById('NavLogo')
  let nlm = document.getElementById('mobNavLogo')
  if (nl && window.innerWidth > 768) nl.style.display = "block"
  if (nlm && window.innerWidth <= 768) nlm.style.display = "block"
  return (
    <div className={`${Styles.Aboutbg}`}>
      <div className={Styles.AboutBody}>
        <div className={`${"width50"}`}>
          <div className={`${"width90"}`}>
            <img src={require('../../assest/images/Artist.jpg')} alt="Artist" />
          </div>
        </div>
        <div className={`${"width50"} ${Styles.AboutContent}`}>
          <div className={`${"width90"}`}>
            <h1>Artist Bio</h1>
            <p>"Led by one love she discovered another."</p>
            <p>That's the story of artist Pooja Anant Bajaj. Dedicated to her late husband, Anant Bajaj, she opens with her first solo art show, 'Unleashed defining the Abstract.' Catch her in the middle of the conversation and you'll will hear her refer to "The man who brought wildlife in my life." </p>
            <p>For Pooja Bajaj, art was a childhood respite between her academics. Growing up, she was always curious with a penchant for creativity that led her to learn oil-ceramic and glass painting early on in life. She rediscovered her love for art during the pandemic. A therapeutic pursuit that helped her cope with the black days of the pandemic. It all started when her son took an online art appreciation course. Her teacher Anoushka Tejuju -founder of the Budding Artists Studio, made her realize her love for the fine arts once again.</p>
            <p>Under her tutelage, Pooja learnt the art of Zen Tangling which eventually led her to discover her love for abstract nature, especially wildlife. With no formal training in art, Pooja was guided by her teacher. Her journey started with pen & ink and a love for stallions. Thus, the Gallop series was born. As her confidence evolved so did her choice for materials and colour palettes. She painted using water colours, mixed media and even acrylics using brushes and artist knivest to create her unique voice as an artist with vivid colours. She enjoyed painting tigers that represented the love for wildlife of her late husband. Giving birth to the Roar series. While the colourful elephants in her Bond of love series pay homage to the joys of motherhood.</p>
            <p>So why does Pooja paint? Because to her, it is a liberating journey to feel comfortable with the uncomfortable. But also, it is a journey to self-expression and telling her story through inspiration from nature. Pooja was introduced to the wild outdoors through the lens of her late husband, Anant who had an infinite love for animals.</p>
            <p>Pooja dedicates her first Art Show to Anant, bringing to life his favourite animals through the bright and bold strokes of her paint brushes and knives.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;