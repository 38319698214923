import React from 'react';
import '../../App.css';
import Styles from './ContactUs.module.css';
import Background from '../../assest/images/contactusbg.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton } from '@mui/material';

const ContactUs = () => {
  let nl = document.getElementById('NavLogo')
  let nlm = document.getElementById('mobNavLogo')
  if (nl && window.innerWidth > 768) nl.style.display = "block"
  if (nlm && window.innerWidth <= 768) nlm.style.display = "block"
  return (
    <div className="AppViews" style={{ backgroundImage: `url(${Background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <div className={Styles.LogoMask}>
        <h3>GET IN TOUCH! </h3>
        <div>
          <a href="https://www.instagram.com/artbypoojabajaj/" target='_blank'>
            <IconButton aria-label="InstagramIcon" className={Styles.IconBtn}>
              <InstagramIcon /><span className={Styles.BtnText}>artbypoojabajaj</span>
            </IconButton>
          </a>
          <a href="mailto:artbypoojabajaj@gmail.com">
            <IconButton aria-label="MailOutlineIcon" className={Styles.IconBtn}>
              <MailOutlineIcon /><span className={Styles.BtnText}>artbypoojabajaj@gmail.com</span>
            </IconButton>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;